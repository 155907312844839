.logo {
    max-height: 100px;
    margin-left: 4vw;
    padding: 5px 0;
}
.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #0d233c;
    color: white;
    top: 0;
    z-index: 9999;
    border: 1px solid black;
}
.nav-header-closed {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}
.navbar a:hover {
    color: red;
}
.closed {
    display: flex;
    padding-right: 2vw;
}
.navbar ul li {
    list-style: none;
}
.navbar ul li:not(:last-child) {
    border-right: 1px solid gray;
}
.navbar ul li a {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
    width: max-content;
}

.mobile-nav-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3vw;
}

.lang-btn {
    height: min-content;
    font-size: small;
    color: white;
    background-color: transparent;
    border: 2px solid #1c3b5e;
    border-radius: 5px;
    cursor: pointer;
    width: min-content;
    aspect-ratio: 1;
}


.menu-btn {
    display: none;
}

@media (max-width: 1024px) {
    .logo {
        max-height: 80px;
    }
    .menu-btn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 3rem;
        height: 2.5rem;
        padding: 10px;
        
    }
    .menu-btn span {
        height: 0.45rem;
        width: 100%;
        background-color: white;
        border-radius: 0.2rem;
    }

    .navbar {
        flex-direction: column;
    }
    .nav-header-open {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid black;
    }

    .navbar ul.closed {
        display: none;
    }

    .open {
        margin: 0;
        padding: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .navbar ul li:not(:last-child) {
        border-right: none;
    }
    .navbar ul li {
        margin: 5px 0;
        width: 95vw;
        border: 1px solid #1c3b5e;
        border-radius: 15px;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
        border: none;
    }
}